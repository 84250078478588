export async function subscriberList(){
    var result = {};
    var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc";
    // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
    var headers = {}
    result = (fetch(url1, {
        method : "GET",
        mode: 'cors',
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          
          return data;
        })
        .catch(function(error) {
          console.error('subscribersList():請求錯誤1,error:'+error.message)
        }))
    return result;
}

export async function subscriberByEmail(email){
  var subscribeResult = {};
  var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc&email_address="+email;
  // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
  const headers = {}
  var subscriberId=0;
  subscribeResult = (await fetch(url1, {
      method : "GET",
      mode: 'cors',
      headers: headers
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.error)
        }
        return response.json();
      })
      .then(data => {
        
        return data;
      })
      .catch(function(error) {
        console.error('subscriberByEmail():請求錯誤1,error:'+error.message)
      }))
      console.log('subscribe list:')
      console.log(subscribeResult)
      //檢測該用戶是否是訂閱者，訂閲是否過期，並顯示可用資源點
      var subscribers = [];
      subscribers = subscribeResult.subscribers;
      if(subscribeResult.total_subscribers==0){
        //todo
        console.log('user-setting-page: Please subscribe to continue using!')
        //todo 是否訂閲
        if(document.getElementById("yourSubscribeState")) {
          document.getElementById('yourSubscribeState').value="0"  
        }
        
      }else{
        var subscribeStatus=false;
        for(var i=0;i<subscribers.length;i++){
          var obj_i=subscribers[i];
          
          if(obj_i.state=="active"){
            subscriberId=obj_i.id;
            subscribeStatus=true;
          }
          
        }
        //todo 是否訂閲
        var tagStatus=0;
        var tagStatus1=0;
        var tagStatus2=0;
        var tagStatus3=0;
        var tagStatus4=0;
        var tagStatus5=0;
        var tagStatus6=0;
        var tagStatus7=0;
        var tagStatus8=0;
        var tagStatus9=0;
        var tagStatus10=0;
        var tagStatus11=0;
        var tagStatus12=0;
        var tagStatus13=0;
        var tagStatus14=0;
        var tagStatus15=0;
        var tagStatus16=0;
        var tagStatus17=0;
        var tagStatus18=0;
        var tagStatus19=0;
        var tagStatus20=0;
        var tagStatus21=0;
        var tagStatus22=0;
        var tagStatus23=0;
        var tagStatus24=0;
        var tagStatus25=0;
        var tagStatus26=0;
        var tagStatus27=0;
        var tagStatus28=0;
        var tagStatus29=0;
        //
        var productName1='gpt4-article';
        var productTagId1=3525768;
        //  
        var productName2='dalle-art';
        var productTagId2=3525770;
        //
        var productName3='dalle-photo';
        var productTagId3=3525772; 
        //
        var productName4='AI21 AI Integrator';
        var productTagId4=3525773; 
        //
        var productName5='nlpcloud-ai-integrator';
        var productTagId5=3525775; 
        //
        var productName6='TaskGPT';
        var productTagId6=3525776; 
        //
        var productName7='Painter007';
        var productTagId7=3525777; 
        //
        var productName8='gpt4-chat pro';
        var productTagId8=3525778; 
        // var productTagId8=3564641;
        //
        var productName9='Sketch2Code';
        var productTagId9=3525779; 
        //
        var productName10='Stable Diffusion Ai Photo';
        var productTagId10=3525780;
        //
        var productName11='Dream Style';
        var productTagId11=3562226; 
        //
        var productName12='AI21 Doc';
        var productTagId12=3562227; 
        //
        var productName13='Cohere AI';
        var productTagId13=3562253; 
        //
        var productName14='dalle-photo pro';
        var productTagId14=3562254; 
        //
        var productName15='gpt4-chat';
        var productTagId15=3564641; 
        //
        var productName16='pixel draw 007';
        var productTagId16=3568172; 
        //
        var productName17='Dream Style Lite';
        var productTagId17=3568173; 
        //
        var productName18='Dream Style Lite Slides';
        var productTagId18=3568174; 
        //
        var productName19='Dream Style Web';
        var productTagId19=3568175; 
        //
        var productName20='Dream Style Doc';
        // var productTagId20=3568176;
        var productTagId20=3562226;
        //
        var productName21='Painter007 Doc';
        // var productTagId21=3613399;
        var productTagId21=3525777;
        //
        var productName22='Painter007 Web';
        // var productTagId22=3613425; 
        var productTagId22=3525777;
        //
        var productName23='TableQA';
        var productTagId23=3665089; 
        //
        var productName24='DocQA';
        var productTagId24=3665090; 
        //
        var productName25='BioGPT';
        var productTagId25=3665092; 
        //
        var productName26='AIArtHub';
        var productTagId26=3968590; 
        //
        var productName27='DocGPT';
        var productTagId27=3968592; 
        //
        var productName28='ChartsGPT';
        var productTagId28=3968593; 
        //
        var productName29='CodeTranslator';
        var productTagId29=3968593; 
        

        
        if(document.getElementById("user-setting-page")) {
          if(subscribeStatus){
            
            //todo 2
            const url2="https://api.convertkit.com/v3/subscribers/"+subscriberId+"/tags?api_key=zHQOrAFeP297mBDucnQg_Q";
            var resultCode=0;
            const response2 = (await fetch(url2, {
              method : "GET",
              mode: 'cors',
              headers: headers
            })
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.error)
              }
              return response.json();
            })
            .then(data => {
              
              return data;
            })
            .catch(function(error) {
              console.error('subscriberByEmail():請求錯誤2,error:'+error.message)
            }))
            console.log('response2:')
            console.log(response2)
            var tags=response2.tags;
            if(tags==undefined){
              console.log('tags==undefined')
              return 'code:1024,msg:sorry,something wrong';
            }
            if(tags.length==0){
              console.log('not subscribe, subscriber have not tags')
              return 0;
            }
            
            for(var i=0;i<tags.length;i++){
              var tagObj=tags[i];
              if(productTagId1==tagObj.id){
                tagStatus1=1;
                tagStatus=1;
              }
              if(productTagId2==tagObj.id){
                tagStatus2=1;
                tagStatus=1;
              }
              if(productTagId3==tagObj.id){
                tagStatus3=1;
                tagStatus=1;
              }
              if(productTagId4==tagObj.id){
                tagStatus4=1;
                tagStatus=1;
              }
              if(productTagId5==tagObj.id){
                tagStatus5=1;
                tagStatus=1;
              }
              if(productTagId6==tagObj.id){
                tagStatus6=1;
                tagStatus=1;
              }
              if(productTagId7==tagObj.id){
                tagStatus7=1;
                tagStatus=1;
              }
              if(productTagId8==tagObj.id){
                tagStatus8=1;
                tagStatus=1;
              }
              if(productTagId9==tagObj.id){
                tagStatus9=1;
                tagStatus=1;
              }
              if(productTagId10==tagObj.id){
                tagStatus10=1;
                tagStatus=1;
              }
              if(productTagId11==tagObj.id){
                tagStatus11=1;
                tagStatus=1;
              }
              if(productTagId12==tagObj.id){
                tagStatus12=1;
                tagStatus=1;
              }
              if(productTagId13==tagObj.id){
                tagStatus13=1;
                tagStatus=1;
              }
              if(productTagId14==tagObj.id){
                tagStatus14=1;
                tagStatus=1;
              }
              if(productTagId15==tagObj.id){
                tagStatus15=1;
                tagStatus=1;
              }
              if(productTagId16==tagObj.id){
                tagStatus16=1;
                tagStatus=1;
              }
              if(productTagId17==tagObj.id){
                tagStatus17=1;
                tagStatus=1;
              }
              if(productTagId18==tagObj.id){
                tagStatus18=1;
                tagStatus=1;
              }
              if(productTagId19==tagObj.id){
                tagStatus19=1;
                tagStatus=1;
              }
              if(productTagId20==tagObj.id){
                tagStatus20=1;
                tagStatus=1;
              }
              if(productTagId21==tagObj.id){
                tagStatus21=1;
                tagStatus=1;
              }
              if(productTagId22==tagObj.id){
                tagStatus22=1;
                tagStatus=1;
              }
              if(productTagId23==tagObj.id){
                tagStatus23=1;
                tagStatus=1;
              }
              if(productTagId24==tagObj.id){
                tagStatus24=1;
                tagStatus=1;
              }
              if(productTagId25==tagObj.id){
                tagStatus25=1;
                tagStatus=1;
              }
              if(productTagId26==tagObj.id){
                tagStatus26=1;
                tagStatus=1;
              }
              if(productTagId27==tagObj.id){
                tagStatus27=1;
                tagStatus=1;
              }
              if(productTagId28==tagObj.id){
                tagStatus28=1;
                tagStatus=1;
              }
              if(productTagId29==tagObj.id){
                tagStatus29=1;
                tagStatus=1;
              }
              
            }
            //
          }
          if(tagStatus1==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus2==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus3==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus4==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus5==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus6==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus7==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus8==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus9==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus10==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus11==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus12==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus13==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus14==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus15==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus16==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus17==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus18==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus19==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus20==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus21==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus22==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus23==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus24==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus25==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus26==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus27==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus28==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          if(tagStatus29==1){
            document.getElementById('yourSubscribeState').value="1"
            //
          }else{
            document.getElementById('yourSubscribeState').value="0"
          }
          
        }  
        if(document.getElementById("user-setting-page")) {
          //顯示訂閲項
          if(tagStatus1==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName1 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus2==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName2 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus3==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName3 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus4==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName4 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus5==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName5 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus6==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName6 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus7==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName7 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus8==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName8 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus9==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName9 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus10==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName10 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus11==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName11 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus12==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName12 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus13==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName13 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus14==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName14 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus15==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName15 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus16==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName16 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus17==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName17 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus18==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName18 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus1==19){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName19 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus20==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName20 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus21==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName21 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus22==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName22 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus23==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName23 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus24==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName24 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus25==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName25 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus26==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName26 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus27==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName27 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus28==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName28 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          if(tagStatus29==1){
            var yourSubscribeTableHtml = ''
            for(var i=0;i<subscribers.length;i++){
              var obj_i=subscribers[i];
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
              yourSubscribeTableHtml = yourSubscribeTableHtml + productName29 +'</a></p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
              //
              yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
              yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
              
            }
            yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
            //
            var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
            yourSubscribeTableEle.innerHTML=yourSubscribeTableEle.innerHTML+yourSubscribeTableHtml;
          }
          
        }
        
      }
  return tagStatus;
}

export async function subscriberByEmail2(email){
  var result = {};
  var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc&email="+email;
  // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
  const headers = {
    "Content-Type": "application/json"
  }
  result = await fetch(url1, {
      method : "GET",
      mode: 'cors',
      headers: headers
    })
      console.log('subscribe list2:')
        console.log(result)
  return result;
}